import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./App.css";

const SignInScreen = ({ onSignIn, onForgotPassword }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = () => {
    setLoading(true);
    if (username === "ProtectedCampus" && password === "PC2025") {
      setTimeout(() => {
        onSignIn();
        setLoading(false);
      }, 1500);
    } else {
      alert("Invalid username or password. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="screen login-screen">
      <div className="login-container">
        <h1 className="login-title">PROTECTED CAMPUS</h1>
        <h3 className="login-subtitle">Digital Blue Light</h3>
        <p className="login-instructions">Log in to access your safety tools.</p>
        <div className="login-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input login-input"
          />
          <button
            className="button login-button"
            onClick={handleSignIn}
            disabled={loading}
          >
            {loading ? "Logging In..." : "Log In"}
          </button>
          <p className="forgot-password-text" onClick={onForgotPassword}>
            Forgot Password?
          </p>
        </div>
      </div>
      <p style={{ color: "white", textAlign: "center", marginTop: "30px" }}>
        Building the Future of a Protected Campus with the Power of the Digital Blue Light!
        {/* Copyright Text */}
<p
  style={{
    color: "white",
    textAlign: "center",
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    margin: "0",
    fontSize: "14px",
  }}
>
  Copyright © 2024 Protected Campus - All Rights Reserved.
</p>
      </p>
    </div>
  );
};

const ResetPasswordScreen = ({ onBack }) => {
  const [email, setEmail] = useState("");

  const handleReset = () => {
    if (email) {
      alert("Password reset link has been sent to your email.");
      onBack();
    } else {
      alert("Please enter your email.");
    }
  };

  return (
    <div className="screen reset-password-screen">
      <div className="reset-container">
        <h2 className="reset-title">Reset Password</h2>
        <p className="reset-instructions">
          Enter your email address below to reset your password.
        </p>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input reset-input"
        />
        <div className="reset-actions">
          <button onClick={handleReset} className="button reset-button">
            Reset Password
          </button>
          <button onClick={onBack} className="button cancel-button">
            Back
          </button>
        </div>
      </div>
      {/* Copyright Text */}
<p
  style={{
    color: "white",
    textAlign: "center",
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    margin: "0",
    fontSize: "14px",
  }}
>
  Copyright © 2024 Protected Campus - All Rights Reserved.
</p>
    </div>
  );
};

const HomeScreen = ({ onSelect, onLogout }) => {
  const [alerts, setAlerts] = useState([]);
  const token = "ca3a7953605e2df8a01312e3aed8277f05c8241f"; // Your token

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}alerts/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const data = await response.json();
        setAlerts(data);
      } catch (error) {
        console.error("Failed to fetch alerts:", error);
      }
    };

    fetchAlerts();
  }, []);

  return (
    <div className="screen home-screen">
      <div className="header-box">
        <h1>PROTECTED CAMPUS</h1>
        <h3>Digital Blue Light</h3>
      </div>
      <div className="button-container">
        <div className="button-box" onClick={() => onSelect("panic")}>
          <h2>Silent Panic Alarm</h2>
        </div>
        <div className="button-box" onClick={() => onSelect("settings")}>
          <h2>Settings</h2>
        </div>
        <div className="button-box logout-box" onClick={onLogout}>
          <h2>Log Out</h2>
        </div>
      </div>
      
      {alerts.length > 0 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            padding: "20px",
            backgroundColor: "#f8f8f8",
            borderRadius: "10px",
          }}
        >
          <h2 style={{ marginBottom: "20px" }}>Recent Alerts</h2>
          <ul style={{ listStyle: "none", padding: "0" }}>
            {alerts.map((alert) => (
              <li
                key={alert.id}
                style={{
                  marginBottom: "15px",
                  padding: "10px",
                  backgroundColor: "#ffffff",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  textAlign: "left",
                }}
              >
                <strong>Location:</strong> {alert.location} <br />
                <strong>Status:</strong> {alert.status} <br />
                <strong>Timestamp:</strong> {new Date(alert.timestamp).toLocaleString()}
              </li>
            ))}
          </ul>
        </div>
      )}

      <p
        style={{
          color: "white",
          textAlign: "center",
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          margin: "0",
          fontSize: "14px",
        }}
      >
        Copyright © 2024 Protected Campus - All Rights Reserved.
      </p>
    </div>
  );
};

const SettingsScreen = ({ onBack }) => {
  const [currentPage, setCurrentPage] = useState("settings");
  const [name, setName] = useState("John Doe");
  const [email, setEmail] = useState("johndoe@example.com");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [locationSharing, setLocationSharing] = useState(true);
  const [surveyResponses, setSurveyResponses] = useState({});

  const handleSave = () => {
    alert("Settings saved successfully!");
  };

  const handleSurveyChange = (question, response) => {
    setSurveyResponses((prev) => ({ ...prev, [question]: response }));
  };

  const handleCancelSurvey = () => {
    setCurrentPage("settings");
  };

  // Main Settings Page
  const renderSettingsPage = () => (
    <div
      className="settings-box"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px",
        maxWidth: "400px",
        margin: "20px auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          color: "#333",
          fontWeight: "bold",
        }}
      >
        SETTINGS:
      </h2>

      {/* Profile Section */}
      <div style={{ marginBottom: "10px" }}>
        <label style={{ display: "block", marginBottom: "5px", color: "black" }}>
          Name:
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input settings-input"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ display: "block", marginBottom: "5px", color: "black" }}>
          Email:
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input settings-input"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ display: "block", marginBottom: "5px", color: "black" }}>
          Phone #:
        </label>
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="input settings-input"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>

      {/* Toggle Settings */}
      <div style={{ marginBottom: "15px" }}>
        <label style={{ color: "black" }}>
          <input
            type="checkbox"
            checked={notificationsEnabled}
            onChange={(e) => setNotificationsEnabled(e.target.checked)}
          />
          Enable Live Audio
        </label>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <label style={{ color: "black" }}>
          <input
            type="checkbox"
            checked={darkMode}
            onChange={(e) => setDarkMode(e.target.checked)}
          />
          Enable Live Video
        </label>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label style={{ color: "black" }}>
          <input
            type="checkbox"
            checked={locationSharing}
            onChange={(e) => setLocationSharing(e.target.checked)}
          />
          Enable Live Location
        </label>
      </div>

      {/* Action Buttons */}
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <button
          onClick={() => setCurrentPage("survey")}
          style={{
            border: "2px solid #007BFF",
            backgroundColor: "white",
            color: "#007BFF",
            borderRadius: "5px",
            padding: "10px 20px",
            margin: "5px 0",
            width: "100%",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Complete Survey
        </button>
        <button
          onClick={() => setCurrentPage("recordings")}
          style={{
            border: "2px solid #28A745",
            backgroundColor: "white",
            color: "#28A745",
            borderRadius: "5px",
            padding: "10px 20px",
            margin: "5px 0",
            width: "100%",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          View Recordings
        </button>
        <button
          onClick={() => setCurrentPage("resetPassword")}
          style={{
            border: "2px solid #FF5733",
            backgroundColor: "white",
            color: "#FF5733",
            borderRadius: "5px",
            padding: "10px 20px",
            margin: "5px 0",
            width: "100%",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Reset Password
        </button>
      </div>

      {/* Save and Back Buttons */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={handleSave}
          style={{
            backgroundColor: "#28A745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "10px 15px",
            fontWeight: "bold",
            cursor: "pointer",
            flex: 1,
            marginRight: "5px",
          }}
        >
          Save Changes
        </button>
        <button
          onClick={onBack}
          style={{
            backgroundColor: "#FF5733",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "10px 15px",
            fontWeight: "bold",
            cursor: "pointer",
            flex: 1,
            marginLeft: "5px",
          }}
        >
          Back
        </button>
      </div>
    </div>
  );

 // Survey Page
const renderSurveyPage = () => (
  <div
    className="survey-section"
    style={{
      padding: "20px",
      margin: "20px auto",
      backgroundColor: "#fff",
      borderRadius: "10px",
      maxWidth: "600px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
    }}
  >
    <h2
      style={{
        color: "#000",
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      Follow-Up Survey:
    </h2>
    <p style={{ marginBottom: "20px", color: "#000" }}>
      All recordings (audio, video, and location) have been downloaded to your
      settings for review and comments.
    </p>
    <div style={{ marginBottom: "15px", textAlign: "left" }}>
      <p
        style={{
          color: "#000",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        1. How would you rate the quality of the call?
      </p>
      <div>
        <label style={{ marginRight: "10px", color: "#000" }}>
          <input
            type="radio"
            name="callQuality"
            value="Excellent"
            onChange={() => handleSurveyChange("callQuality", "Excellent")}
          />
          Excellent
        </label>
        <label style={{ marginRight: "10px", color: "#000" }}>
          <input
            type="radio"
            name="callQuality"
            value="Good"
            onChange={() => handleSurveyChange("callQuality", "Good")}
          />
          Good
        </label>
        <label style={{ color: "#000" }}>
          <input
            type="radio"
            name="callQuality"
            value="Poor"
            onChange={() => handleSurveyChange("callQuality", "Poor")}
          />
          Poor
        </label>
      </div>
    </div>
    <div style={{ marginBottom: "15px", textAlign: "left" }}>
      <p
        style={{
          color: "#000",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        2. Did you find the application beneficial during your emergency?
      </p>
      <div>
        <label style={{ marginRight: "10px", color: "#000" }}>
          <input
            type="radio"
            name="responseTime"
            value="Yes"
            onChange={() => handleSurveyChange("responseTime", "Yes")}
          />
          Yes
        </label>
        <label style={{ color: "#000" }}>
          <input
            type="radio"
            name="responseTime"
            value="No"
            onChange={() => handleSurveyChange("responseTime", "No")}
          />
          No
        </label>
      </div>
    </div>
    <div style={{ marginBottom: "15px", textAlign: "left" }}>
      <p
        style={{
          color: "#000",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        3. Describe the situation that prompted you to utilize the Digital Blue Light:
      </p>
      <textarea
        name="situationDescription"
        style={{
          width: "80%",
          padding: "10px",
          borderRadius: "10px",
          border: "1px solid #ccc",
          minHeight: "80px",
          margin: "0 auto",
          display: "block",
          textAlign: "left",
          color: "#000",
        }}
        onChange={(e) =>
          handleSurveyChange("situationDescription", e.target.value)
        }
      />
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      <button
        onClick={() => {
          console.log("Survey Responses:", surveyResponses);
          alert("Survey Submitted!");
          setCurrentPage("settings");
        }}
        className="button complete-survey-button"
        style={{
          backgroundColor: "#4CAF50",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        Submit Survey
      </button>
      <button
        onClick={handleCancelSurvey}
        className="button skip-survey-button"
        style={{
          backgroundColor: "#FF5733",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        Cancel
      </button>
    </div>
  </div>
);

const renderRecordingsPage = () => (
  <div
    className="recordings-box"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "20px",
      maxWidth: "700px",
      margin: "20px auto",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h2
      style={{
        textAlign: "center",
        marginBottom: "20px",
        color: "#000", // Black text
        fontWeight: "bold",
        fontSize: "24px",
        textTransform: "uppercase", // All caps
      }}
    >
      Recordings:
    </h2>
    <p style={{ color: "#555", textAlign: "center", marginBottom: "20px" }}>
      Access and manage your recordings. Recordings include Audio, Video, and Location.
    </p>

    {/* Search and Filter */}
    <div style={{ display: "flex", marginBottom: "20px" }}>
      <input
        type="text"
        placeholder="Search recordings..."
        style={{
          flex: 1,
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          marginRight: "10px",
        }}
      />
      <select
        style={{
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <option value="all">All Types</option>
        <option value="audio">Audio</option>
        <option value="video">Video</option>
        <option value="location">Location</option>
      </select>
    </div>

    {/* Recordings List */}
    <div
      style={{
        maxHeight: "300px",
        overflowY: "auto",
        border: "1px solid #ddd",
        borderRadius: "5px",
        marginBottom: "20px",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div>
          <p
            style={{
              margin: 0,
              color: "#333",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Recording 1
          </p>
          <p style={{ margin: "5px 0", color: "#777", fontSize: "14px" }}>
            Date: 12/10/2024 | Duration: 3m 45s
          </p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              backgroundColor: "#28A745",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Play
          </button>
          <button
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div>
          <p
            style={{
              margin: 0,
              color: "#333",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Recording 2
          </p>
          <p style={{ margin: "5px 0", color: "#777", fontSize: "14px" }}>
            Date: 12/12/2024 | Duration: 5m 30s
          </p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              backgroundColor: "#28A745",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Play
          </button>
          <button
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div>
          <p
            style={{
              margin: 0,
              color: "#333",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Recording 3
          </p>
          <p style={{ margin: "5px 0", color: "#777", fontSize: "14px" }}>
            Date: 12/15/2024 | Duration: 2m 15s
          </p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              backgroundColor: "#28A745",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Play
          </button>
          <button
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              borderRadius: "5px",
              padding: "5px 15px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </div>
    </div>

    {/* Back Button */}
    <button
      onClick={() => setCurrentPage("settings")}
      style={{
        backgroundColor: "#FF5733",
        color: "white",
        borderRadius: "5px",
        padding: "10px",
        border: "none",
        cursor: "pointer",
        width: "100%",
        fontWeight: "bold",
      }}
    >
      Back to Settings
    </button>
  </div>
);

// Reset Password Page
const renderResetPasswordPage = () => (
  <div
    className="reset-password-container"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "20px",
      maxWidth: "400px",
      margin: "20px auto",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
    }}
  >
    <h2
      style={{
        color: "#000", // Black text
        marginBottom: "20px",
        fontWeight: "bold",
        textTransform: "uppercase", // All caps
        fontSize: "22px",
      }}
    >
      Reset Password:
    </h2>
    <p style={{ color: "#555", marginBottom: "20px" }}>
      Please enter and confirm your new password below.
    </p>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <input
        type="password"
        placeholder="Enter New Password"
        style={{
          width: "100%",
          maxWidth: "300px", // Center alignment
          padding: "12px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          marginBottom: "15px",
          fontSize: "14px",
        }}
      />
      <input
        type="password"
        placeholder="Confirm New Password"
        style={{
          width: "100%",
          maxWidth: "300px", // Center alignment
          padding: "12px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          marginBottom: "15px",
          fontSize: "14px",
        }}
      />
    </div>
    <button
      onClick={() => {
        alert("Password Reset Successfully!");
        setCurrentPage("settings");
      }}
      style={{
        backgroundColor: "#28A745",
        color: "white",
        padding: "10px 20px",
        borderRadius: "8px",
        border: "none",
        fontWeight: "bold",
        fontSize: "16px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
      }}
    >
      Reset Password
    </button>
    <button
      onClick={() => setCurrentPage("settings")}
      style={{
        backgroundColor: "#FF5733",
        color: "white",
        padding: "10px 20px",
        borderRadius: "8px",
        border: "none",
        fontWeight: "bold",
        fontSize: "16px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
      }}
    >
      Cancel
    </button>
  </div>
);

  return (
    <div className="screen settings-screen">
      {currentPage === "settings" && renderSettingsPage()}
      {currentPage === "survey" && renderSurveyPage()}
      {currentPage === "recordings" && renderRecordingsPage()}
      {currentPage === "resetPassword" && renderResetPasswordPage()}

      {/* Copyright Text */}
      <p
        style={{
          color: "white",
          textAlign: "center",
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          margin: "0",
          fontSize: "14px",
        }}
      >
        Copyright © 2024 Protected Campus - All Rights Reserved.
      </p>
    </div>
  );
};
const PanicScreen = ({ onCancel }) => {
  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null); // Store the media stream reference
  const [startTime, setStartTime] = useState(Date.now()); // Initialize with current time
  const [liveDuration, setLiveDuration] = useState("00:00");
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [loading, setLoading] = useState(true);
  const [isAudioRecording, setIsAudioRecording] = useState(false); // Audio status
  const [isVideoRecording, setIsVideoRecording] = useState(false); // Video status
  const [isFrontCamera, setIsFrontCamera] = useState(true); // Track front/back camera
  const [alertText, setAlertText] = useState(
    "CAMPUS SECURITY HAS BEEN ALERTED AND SHARED"
  );
  const [showSurvey, setShowSurvey] = useState(false); // Track if survey should be shown
  const [surveyResponses, setSurveyResponses] = useState({}); // Store survey responses

  // Prevent video from going full screen
  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const preventFullScreen = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

      videoElement.setAttribute("playsinline", "true");
      videoElement.addEventListener("fullscreenchange", () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      });

      videoElement.addEventListener("click", preventFullScreen);
      videoElement.addEventListener("dblclick", preventFullScreen);

      return () => {
        videoElement.removeEventListener("fullscreenchange", () => {});
        videoElement.removeEventListener("click", preventFullScreen);
        videoElement.removeEventListener("dblclick", preventFullScreen);
      };
    }
  }, []);

  // Start media stream (video and audio)
  const startMediaStream = async () => {
    try {
      const constraints = {
        video: { facingMode: isFrontCamera ? "user" : "environment" },
        audio: true,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      mediaStreamRef.current = stream; // Save the stream reference

      setIsAudioRecording(
        stream.getAudioTracks().some((track) => track.enabled)
      );
      setIsVideoRecording(
        stream.getVideoTracks().some((track) => track.enabled)
      );
    } catch (error) {
      console.error("Error accessing camera or microphone:", error);
      alert(
        "Could not access the camera or microphone. Please check your permissions."
      );
    }
  };

  // Stop media stream (video and audio)
  const stopMediaStream = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setIsAudioRecording(false);
    setIsVideoRecording(false);
  };

  // Handle "End Call" button
  const handleEndCall = () => {
    stopMediaStream();
    setShowSurvey(true);
  };

  // Flip Camera Button Handler
  const handleFlipCamera = async () => {
    stopMediaStream();
    setIsFrontCamera((prev) => !prev);
    await startMediaStream(); // Restart stream without resetting startTime
  };

  // Initialize media stream on component mount
  useEffect(() => {
    startMediaStream();

    return () => {
      stopMediaStream();
    };
  }, [isFrontCamera]);

  // Live session timer
  useEffect(() => {
    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const minutes = Math.floor(elapsed / 60000);
      const seconds = Math.floor((elapsed % 60000) / 1000);
      setLiveDuration(
        `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  // Get user location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching location:", error);
          setLoading(false);
        }
      );
    }
  }, []);

  const blueDotIcon = new L.DivIcon({
    className: "custom-dot",
    html: `<div style="width: 12px; height: 12px; background-color: blue; border-radius: 50%;"></div>`,
  });

  const handleInclude911 = () => {
    setAlertText("CAMPUS SECURITY + 911 HAS BEEN ALERTED AND SHARED");
  };

  const handleSurveyChange = (question, response) => {
    setSurveyResponses((prev) => ({ ...prev, [question]: response }));
  };

  return (
    <div className="screen panic-screen">
      {!showSurvey ? (
        <>
          {/* Alert Box */}
          <div
            style={{
              backgroundColor: "#2A61D6",
              color: "#fff",
              padding: "15px 20px",
              borderRadius: "8px",
              textAlign: "center",
              maxWidth: "80%",
              margin: "20px auto",
              fontSize: "18px",
              fontWeight: "bold",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            }}
          >
            {alertText}
          </div>

          {/* Video Section */}
          <div className="video-section">
            <div className="video-overlay">
              <span className="live-indicator">
                <span className="red-dot"></span> LIVE
              </span>
              <span className="timer">{liveDuration}</span>
            </div>
            <video
              id="video"
              ref={videoRef}
              autoPlay
              muted={false}
              className="video-stream"
            ></video>
            <button
              className="flip-camera-button"
              onClick={handleFlipCamera}
            >
              Flip Camera
            </button>
          </div>

          {/* Recording Status */}
          <div
            className="recording-status"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px auto",
            }}
          >
            <div
              style={{
                backgroundColor: "#000",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              AUDIO RECORDING: {isAudioRecording ? "ACTIVE" : "INACTIVE"}
            </div>
          </div>

          {/* Map Section */}
          <div className="map-section" style={{ marginTop: "10px" }}>
            {loading ? (
              <p>Loading map...</p>
            ) : (
              <MapContainer
                center={[location.latitude, location.longitude]}
                zoom={16}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[location.latitude, location.longitude]}
                  icon={blueDotIcon}
                />
              </MapContainer>
            )}
          </div>

          {/* Control Bar */}
          <div className="control-bar">
            <button
              className="control-button include-911-button"
              onClick={handleInclude911}
              style={{
                backgroundColor: "#fff",
                color: "#000",
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Include 911
            </button>
            <button
              className="control-button end-call-button"
              onClick={handleEndCall}
              style={{
                backgroundColor: "#ff4d4d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              End Call
            </button>
          </div>
        </>
      ) : (
        // Survey Section
        <div
          className="survey-section"
          style={{
            padding: "20px",
            margin: "20px auto",
            backgroundColor: "#fff",
            borderRadius: "10px",
            maxWidth: "600px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <h2 style={{ color: "#000" }}>FOLLOW-UP SURVEY (OPTIONAL):</h2>
          <p style={{ marginBottom: "20px", color: "#000" }}>
            All recordings (audio, video, and location) have been downloaded to
            your settings for review and comments.
          </p>
          <div style={{ marginBottom: "15px", textAlign: "left" }}>
            <p style={{ color: "#000", fontWeight: "bold" }}>
              1. How would you rate the quality of the call?
            </p>
            <div>
              <label style={{ marginRight: "10px", color: "#000" }}>
                <input
                  type="radio"
                  name="callQuality"
                  value="Excellent"
                  onChange={() =>
                    handleSurveyChange("callQuality", "Excellent")
                  }
                />
                Excellent
              </label>
              <label style={{ marginRight: "10px", color: "#000" }}>
                <input
                  type="radio"
                  name="callQuality"
                  value="Good"
                  onChange={() => handleSurveyChange("callQuality", "Good")}
                />
                Good
              </label>
              <label style={{ color: "#000" }}>
                <input
                  type="radio"
                  name="callQuality"
                  value="Poor"
                  onChange={() => handleSurveyChange("callQuality", "Poor")}
                />
                Poor
              </label>
            </div>
          </div>
          <div style={{ marginBottom: "15px", textAlign: "left" }}>
            <p style={{ color: "#000", fontWeight: "bold" }}>
              2. Did you find the application beneficial during your emergency?
            </p>
            <div>
              <label style={{ marginRight: "10px", color: "#000" }}>
                <input
                  type="radio"
                  name="responseTime"
                  value="Yes"
                  onChange={() =>
                    handleSurveyChange("responseTime", "Yes")
                  }
                />
                Yes
              </label>
              <label style={{ color: "#000" }}>
                <input
                  type="radio"
                  name="responseTime"
                  value="No"
                  onChange={() => handleSurveyChange("responseTime", "No")}
                />
                No
              </label>
            </div>
          </div>
          <div style={{ marginBottom: "15px", textAlign: "left" }}>
            <p style={{ color: "#000", fontWeight: "bold" }}>
              3. Describe the situation that prompted you to utilize the Digital Blue Light:
            </p>
            <textarea
              name="situationDescription"
              style={{
                width: "80%",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #ccc",
                minHeight: "80px",
                margin: "0 auto",
                display: "block",
                textAlign: "left",
                color: "#000",
              }}
              onChange={(e) =>
                handleSurveyChange("situationDescription", e.target.value)
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              onClick={() => {
                console.log("Survey Responses:", surveyResponses);
                alert("Survey Submitted!");
                onCancel();
              }}
              className="button complete-survey-button"
              style={{
                backgroundColor: "#4CAF50",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Submit Survey
            </button>
            <button
              onClick={() => {
                alert("Survey Skipped. Returning to Home Screen.");
                onCancel();
              }}
              className="button skip-survey-button"
              style={{
                backgroundColor: "#FF5733",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Skip Survey
            </button>
          </div>
        </div>
      )}

      {/* Copyright Text */}
      <p
        style={{
          color: "white",
          textAlign: "center",
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          margin: "0",
          fontSize: "14px",
        }}
      >
        Copyright © 2024 Protected Campus - All Rights Reserved.
      </p>
    </div>
  );
};
export default function App() {
  const [screen, setScreen] = useState("login");

  return (
    <div className="App">
      {screen === "login" && (
        <SignInScreen
          onSignIn={() => setScreen("home")}
          onForgotPassword={() => setScreen("reset")}
        />
      )}
      {screen === "reset" && <ResetPasswordScreen onBack={() => setScreen("login")} />}
      {screen === "home" && (
        <HomeScreen
          onSelect={(page) => setScreen(page)}
          onLogout={() => setScreen("login")}
        />
      )}
      {screen === "panic" && <PanicScreen onCancel={() => setScreen("home")} />}
      {screen === "settings" && <SettingsScreen onBack={() => setScreen("home")} />}
    </div>
  );
}
